import React from "react";

import "./home.scss";

class Home extends React.Component {
    render() {
        const facebookGroupLink = "https://www.facebook.com/groups/2492596554391633";
        const nextdoorLink = "https://nextdoor.com";
        return (
            <div id="home">
                <h2>Welcome!</h2>
                <p>
                    This is the web home for the Spanish Trail Property Owners Association
                    in Granbury, Texas.
                </p>
                <p>
                    Remember, our speed limit is only 25 MPH!<br/>
                    Slow down and save lives.
                </p>
                <p>
                    Big shout out and many thanks to Donald Linney and his staff at Hood County Road Operations for clearing the enormous tree hanging over Spanish Trail Drive!
                </p>
                <p>
                    Please join our Facebook group <a href={facebookGroupLink}>Friends of Spanish Trail</a> or
                    <a href={nextdoorLink}> Nextdoor</a> for more up-to-date information.
                </p>
                <p>
                    To apply for an Access Card, download the Access Card Agreement form found within the "Forms & More" 
                    page, under the "Standard Documents" section. This form must be filled out and sent to the STPOA 
                    P.O. Box listed at the top of the form.
                    <span className="bold">&nbsp;If applying for a replacement Access Card</span>
                    , a $25 fee will apply. Please send a $25 check in the mail with the application to help speed up the process. 
                </p>
                <div id="upcoming-events">
                    <span className="bold">Upcoming events</span>
                    <ul>
                        <li>
                            The next board meeting will be held on July 9, 2024, 7:00 PM, at 1308 Spanish Trail Drive.
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Home;
